import React from "react"

import PageBody from "../../components/page-body/pageBody"
import SEO from "../../components/seo"

import Banner from "../../components/banner/banner"
import SponsorDescription from "../../components/sponsor-description/sponsorDescription"
import bg from "../../images/banners/about-our-sponsors.jpg"
import BorderTitle from "../../components/border-title/borderTitle"
import { aboutOurSponsorsPage } from "../../utils/text"

import "./our-sponsors.scss"

var OurSponsors = props => (
  <>
    <SEO title="Home" keywords={["gatsby", "application", "react"]} />
    <div className="our-sponsors-page">
      <Banner
        src={bg}
        title={aboutOurSponsorsPage.banner.title}
        subTitle={aboutOurSponsorsPage.banner.subTitle}
        height="27.5rem"
        backgroundPosY="20%"
      />
      <PageBody className="page">
        <div className="sponsor-decription" />
        {aboutOurSponsorsPage.sponsors.map((sponsor, index) => (
          <SponsorDescription
            className={index % 2 === 1 ? "right" : ""}
            images={sponsor.images}
            name={sponsor.name}
            title={sponsor.title}
            degrees={sponsor.degrees}
            email={sponsor.email}
            key={sponsor.name}
            widthCorrection={sponsor.widthCorrection}
          >
            {sponsor.text}
          </SponsorDescription>
        ))}
      </PageBody>
    </div>
  </>
)

export default OurSponsors
