import React from "react"
import FancyTitle from "../fancy-title/fancyTitle"

import "./sponsorDescription.scss"

var SponsorDescription = props => (
  <div className={`sponsor-description-component ${props.className || ""}`}>
    <FancyTitle className="title" title={props.name} />
    <div className="images">
      {props.images.map((image, index) => (
        <div key={image} className="image">
          <img
            style={
              props.widthCorrection && props.widthCorrection[index]
                ? { width: props.widthCorrection[index] }
                : {}
            }
            src={image}
            alt=""
          />
        </div>
      ))}
    </div>
    <div className="text">{props.children}</div>
  </div>
)

export default SponsorDescription
